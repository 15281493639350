
	import Vue from "vue"
	import Component from "vue-class-component"
	import Page from "custom/Page.vue"
	import Highlights from "custom/widget/Highlights.vue"
	import DocumentsWidget from "custom/widget/DocumentsWidget.vue"
	import MessagesWidget from "custom/widget/MessagesWidget.vue"
	import ProfileWidget from "custom/widget/ProfileWidget.vue"
	import ArrangeImmediatelyWidget from "custom/widget/ArrangeImmediatelyWidget.vue"
	import WelcomeCard from "custom/widget/WelcomeCard.vue"
	import { authenticationModule, userModule } from "store/modules"
	import { StoreAction } from "@lib/types/vuex"
	import { AuthenticationMethod, sessionFactory } from "utils/session-factory"
	import { LoginActionPayload } from "store/authentication/actions"
	import Loader from "custom/Loader.vue"

	// NOTE: Please don't add @Secured here.
	@Component({
		components: {
			Page,
			Highlights,
			MessagesWidget,
			ProfileWidget,
			DocumentsWidget,
			ArrangeImmediatelyWidget,
			WelcomeCard,
			Loader
		}
	})
	export default class Index extends Vue {
		@authenticationModule.Action login!: StoreAction<LoginActionPayload>
		@userModule.Getter isUserLoading!: boolean

		private static readonly SAML_ART_PARAM = "SAMLart"

		async mounted(): Promise<void> {
			if (!process.env.SERVER) {
				await this.handleSamlArtResponse()
			}
		}

		async handleSamlArtResponse(): Promise<void> {
			const urlParams = new URLSearchParams(window.location.search)
			const samlArt = urlParams.get(Index.SAML_ART_PARAM)

			if (samlArt) {
				await this.login({ authenticationMethod: AuthenticationMethod.ERECOGNITION })
			} else {
				sessionFactory.session.verify()
			}
		}
	}
