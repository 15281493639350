import Vue from "vue"
import Component from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator"
import {get} from "lodash-es"

@Component
export default class InputField<T = any> extends Vue {
	@Prop({ required: false }) value?: T
	@Prop({ required: false, type: String }) state?: string
	@Prop({ required: false, type: String }) getter?: string
	@Prop({ required: false, type: String }) action?: string

	protected internalValue: any = ""

	created(): void {
		this.internalValue = this.value
	}

	@Watch("value")
	valueChanged(value: T): void {
		this.internalValue = value
	}

	protected get contents(): T {
		if (this.state) {
			return get(this.$store.state, this.state.split("/"))
		} else if (this.getter) {
			return this.$store.getters[this.getter]
		} else {
			return this.internalValue
		}
	}

	protected set contents(value: T) {
		const action = this.action || this.state || this.getter
		if (action) {
			this.$store.dispatch(action, value)
				.then(() => {
					this.internalValue = value
					this.$emit("input", value)
					this.$emit("errors", [])
				})
				.catch(
					error => {
						if (error.errors) {
							this.$emit("errors", error.errors)
						}
					}
				)
		} else {
			this.internalValue = value
			this.$emit("input", value)
		}
	}

}
