
	import Vue from "vue"
	import Component from "vue-class-component"
	import Highlight from "custom/Highlight.vue"

	@Component({
		components: {
			Highlight
		}
	})
	export default class Highlights extends Vue {
		readonly baseTranslationKey = "highlights"
	}
