
	import Vue from "vue"
	import Component from "vue-class-component"
	import PIcon from "@portal-components/ui/PIcon.vue"
	import { Prop } from "vue-property-decorator"

	@Component({
		components: {
			PIcon
		}
	})
	export default class PMobileLink extends Vue {
		@Prop({ required: true, type: String }) url!: string
		@Prop({ required: true, type: String }) icon!: string
	}
