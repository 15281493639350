
	import Vue from "vue"
	import { Component } from "vue-property-decorator"
	import DayPart from "@components/supplemental/DayPart.vue"
	import Jumbotron from "@components/panel/Jumbotron.vue"
	import { User } from "store/user/types"
	import { companyModule, userModule } from "store/modules"
	import { Contact } from "store/company/types"
	import localStorageClient from "api/clients/local-storage-client"
	import { Company } from "store/company/types"

	@Component({
		components: {
			DayPart,
			Jumbotron
		}
	})
	export default class WelcomeCard extends Vue {
		@userModule.Getter user!: User
		@companyModule.Getter contact!: Contact
		@companyModule.Getter company!: Company

		readonly baseTranslationKey = "welcomeCard"
		
		getImpersonate()
		{
			const checkImpersonate = localStorageClient.getItem("CHECK_IMPERSONATE")
			if(checkImpersonate == '1')
				return ', namens '+this.company.name
			else
				return ''
		}
		get name(): string | undefined {
			const checkImp = this.getImpersonate()
			return this.user.fullName+checkImp || `${ this.contact.name }`
		}
	}
