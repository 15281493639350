
	import Vue from "vue"
	import { Component } from "vue-property-decorator"
	import { namespace } from "vuex-class"

	import PMobileLink from "@portal-components/dashboard/PMobileLink.vue"
	import Badge from "@components/supplemental/Badge.vue"
	import Card from "@components/panel/Card.vue"
	import CardSection from "@components/supplemental/CardSection.vue"
	import Checkbox from "@components/form/Checkbox.vue"
	import LinkButton from "@components/button/LinkButton.vue"
	import List from "@components/generic/List.vue"
	import Message from "@components/widget/Message.vue"
	import PIcon from "@portal-components/ui/PIcon.vue"
	import { StoreAction } from "@lib/types/vuex"
	import { Document } from "store/documents/types"
	import { Message as MessageType } from "store/messages/types"
	import { namespaces } from "store/index"

	const documents = namespace(namespaces.documents)
	const messages = namespace(namespaces.messages)

	@Component({
		components: {
			Badge,
			Card,
			CardSection,
			Checkbox,
			LinkButton,
			List,
			Message,
			PIcon,
			PMobileLink
		}
	})
	export default class DocumentsWidget extends Vue {
		@messages.Getter sortedMessages!: Array<MessageType>
		@messages.Getter unreadMessages!: number

		@documents.Getter documents!: Array<Document>
		@documents.Action downloadAttachment!: StoreAction<string, Blob>
		@documents.Getter unreadDocuments!: number

		private readonly visibleAmount = 3
		readonly baseTranslationKey = "documentsWidget"

		async downloadDocument(document: Document): Promise<void> {
			const blob = await this.downloadAttachment(document.id)
			const blobUrl = URL.createObjectURL(blob)
			const fileName = document.fileName
			const downloadLink = this.$refs.download as HTMLAnchorElement
			downloadLink.href = blobUrl
			downloadLink.download = fileName
			downloadLink.click()
			URL.revokeObjectURL(blobUrl)
		}

		get visibleMessages(): Array<MessageType> {
			return this.sortedMessages.slice(0, this.visibleAmount)
		}

		get visibleDocuments(): Array<Document> {
			return this.documents.slice(0, this.visibleAmount)
		}

		stripExtension(text: string): string {
			const pattern = /\.([a-zA-Z0-9]{2,4})$/
			return text.replace(pattern, "")
		}

		goToInbox(): void {
			location.href = "inbox.html"
		}
	}
