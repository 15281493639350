
	import Vue from "vue"
	import Component from "vue-class-component"
	import ActionButton from "@components/button/ActionButton.vue"

	@Component({
		components: {
			ActionButton
		}
	})
	export default class Highlight extends Vue {}
