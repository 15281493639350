
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop } from "vue-property-decorator"

	@Component
	export default class Message extends Vue {
		@Prop({type: Boolean, default: false}) read!: boolean

		hasDate = false
		hasIcon = false

		mounted(): void {
			this.hasDate = !!this.$slots.date
			this.hasIcon = !!this.$slots.icon
		}

		clicked(event: MouseEvent): void {
			this.$emit("click", event)
		}
	}
