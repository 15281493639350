
	import Vue from "vue"
	import { Component } from "vue-property-decorator"

	import Card from "@components/panel/Card.vue"
	import CardSection from "@components/supplemental/CardSection.vue"
	import IcsIcon from "custom/ui/IcsIcon.vue"
	import { Agreement, DataDeliveryChannel } from "store/company/types"
	import { companyModule } from "store/modules"
	import { EmployeesTabs } from "pages/Employees.vue"

	@Component({
		components: {
			Card,
			CardSection,
			IcsIcon
		}
	})
	export default class ArrangeImmediatelyWidget extends Vue {
		@companyModule.Getter agreement?: Agreement
		readonly baseTranslationKey = "arrangeImmediatelyWidget"
		readonly provideMethodEnum = DataDeliveryChannel
		readonly tabKeys = EmployeesTabs

		getPageUrl(page: string, tab?: string): string {
			return tab ? `${ page }?tab=${ tab }` : page
		}
	}
