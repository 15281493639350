
	import Vue from "vue"
	import { Component } from "vue-property-decorator"
	import Card from "@components/panel/Card.vue"
	import CardSection from "@components/supplemental/CardSection.vue"
	import PIcon from "@portal-components/ui/PIcon.vue"
	import PButton from "@portal-components/ui/input/PButton.vue"
	import PMobileLink from "@portal-components/dashboard/PMobileLink.vue"

	import { Contact, Intermediary } from "store/company/types"
	import { companyModule, userModule } from "store/modules"
	import { User } from "store/user/types"

	@Component({
		components: {
			Card,
			CardSection,
			PIcon,
			PButton,
			PMobileLink
		}
	})
	export default class ProfileWidget extends Vue {
		@companyModule.Getter intermediary!: Intermediary
		@companyModule.Getter contact!: Contact
		@userModule.Getter user!: User

		readonly baseTranslationKey = "profileWidget"

		goToProfile(): void {
			location.href = "profile.html"
		}

		get email(): string | undefined {
			return this.user.email || this.contact.email
		}
	}
