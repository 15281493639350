
	import Vue from "vue"
	import Component from "vue-class-component"
	import { namespace } from "vuex-class"
	import { StoreAction } from "@lib/types/vuex"
	import { Message } from "store/messages/types"

	import PMobileLink from "@portal-components/dashboard/PMobileLink.vue"
	import LinkButton from "@components/button/LinkButton.vue"
	import Card from "@components/panel/Card.vue"
	import List from "@components/generic/List.vue"
	import Badge from "@components/supplemental/Badge.vue"
	import PIcon from "@portal-components/ui/PIcon.vue"

	const messages = namespace("messages")

	@Component({
		components: {
			LinkButton,
			Card,
			List,
			Badge,
			PIcon,
			PMobileLink
		}
	})
	export default class MessagesWidget extends Vue {
		@messages.Getter readonly unreadMessages!: number
		@messages.State private readonly messages!: Array<Message>
		@messages.Action private readonly markMessageAsRead!: StoreAction<Message>

		readonly urlToMessagesPage = "inbox.html"
		private static readonly MAX_MESSAGE_HISTORY = 3

		get newestMessages(): Array<Message> {
			if (!this.messages) {
				return []
			}
			return this.messages.slice(0, MessagesWidget.MAX_MESSAGE_HISTORY)
		}

		async selectMessage(messageId: string): Promise<void> {
			const message = this.messages.find(msg => msg.id === messageId)
			await this.markMessageAsRead(message)
		}
	}
