
	import Vue from "vue"
	import Component from "vue-class-component"

	@Component
	export default class Jumbotron extends Vue {
		get hasSubtitle(): boolean {
			return !!this.$slots.subtitle
		}
	}
